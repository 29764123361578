import { FieldSize } from '@manager'

export const parseSizeToGrid = (size: FieldSize) => {
  switch (size) {
    case FieldSize.SMALL:
      return 'col-span-full md:col-span-1'
    case FieldSize.MEDIUM:
      return 'col-span-full md:col-span-2'
    case FieldSize.LARGE:
      return 'col-span-full md:col-span-3'
    case FieldSize.EXTRA_LARGE:
      return 'col-span-full md:col-span-5'
    default:
    case FieldSize.BLOCK:
      return 'col-span-full'
  }
}
